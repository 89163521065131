import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';

import { LoginSkeleton } from '@/components/login-skeleton';
import { useAcceptShareLink, useLogin } from '@/core/hooks';
import { useEffect } from 'react';

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      token: context.query.token || null,
      folder_id: context.query.folder_id || null,
      invite_id: context.query.invite_id || null
    }
  };
};

export default function Login({
  token,
  folder_id,
  invite_id
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { push } = useRouter();
  const { data: user } = useLogin({ token });
  const mutation = useAcceptShareLink();

  useEffect(() => {
    if (user && invite_id) {
      mutation.mutate({ id: invite_id });
      push('/sharing/shared');
    } else if (user && user.isLimitedAccess) {
      push('/sharing/shared');
    } else if (user && user && folder_id) {
      push(`/home?folder_id=${folder_id}`);
    } else if (user && user) {
      push('/home');
    }
  }, [user]);

  return <LoginSkeleton />;
}
